<template>
  <div class="apply">
    <Container>
      <b-row>
        <b-col md="8" order="0" order-md="0" class="margins__mobile-md-double">
          <Card padding="medium">
            <Margins>
              <ApplyHeaderPicture class="card__image" />
              <ApplyAssetCard class="margins__double" />
              <ApplyYourPlan />
              <b-row align-v="center" align-h="center" class="margins__double">
                <b-col cols="auto">
                  <Button variant="link" @click="removeBundle">
                    {{ $t('CANCEL') }}
                  </Button>
                </b-col>
                <b-col cols="auto">
                  <Button
                    @click="
                      $router.push({
                        name: ROUTES.SELECT_STORE.name,
                      })
                    "
                    variant="inverse-primary"
                  >
                    {{ $t('APPLY') }}
                  </Button>
                </b-col>
              </b-row>
            </Margins>
          </Card>
        </b-col>
        <b-col md="4" order="1" order-md="1">
          <Card padding="medium">
            <Margins>
              <template>
                <ApplyFaq />
              </template>
              <Separator hasLine size="medium" />
              <ApplyContactForm />
            </Margins>
          </Card>
        </b-col>
      </b-row>
    </Container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { SET_SELECTED_BUNDLE, GET_IS_ADMIN } from '@/types';
import { Container, Card, Margins, Separator, Button } from '@/components';
import {
  ApplyHeaderPicture,
  ApplyAssetCard,
  ApplyYourPlan,
  ApplyContactForm,
  ApplyFaq,
} from '@/containers/Apply';
import { constants } from '@/mixins';

export default {
  name: 'Apply',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Separator,
    Button,
    ApplyHeaderPicture,
    ApplyAssetCard,
    ApplyYourPlan,
    ApplyContactForm,
    ApplyFaq,
  },
  methods: {
    ...mapActions([SET_SELECTED_BUNDLE, GET_IS_ADMIN]),
    removeBundle() {
      this.SET_SELECTED_BUNDLE(null);
      this.$router.push({ name: this.ROUTES.SELECT_BUNDLE.name });
    },
  },
};
</script>
